import React, { Component } from 'react'

import PropTypes from 'prop-types';
import { Statistic, Loader, Dimmer } from 'semantic-ui-react';
import callApi from '../../../util/callApi';

class SingleStatistic extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true,
        }
    }

    componentDidMount() {

        callApi(this.props.apiUrl, 'dashboard')
            .then(data => {
                if (data.statusCode === 200) {
                    console.log("Pages Scraped data : ", data)
                    this.setState({ data: { value: data.body || 0, label: 'Pages Scraped' }, isLoading: false })
                } else {
                    this.setState({ data: { value: data.statusCode, label: 'ERROR! STATUS CODE' }, isLoading: false })
                }
            }).catch(console.log)
    }

    render() {
        const { color } = this.props;
        const { data, isLoading } = this.state;
        return (
            <>
                {!isLoading ?

                    <Statistic inverted size='huge' color={color} style={{ height: '100%', marginTop: '5em' }}>
                        <Statistic.Value>{data.value}</Statistic.Value>
                        <Statistic.Label>{data.label}</Statistic.Label>
                    </Statistic> :
                    <Dimmer active>
                        <Loader>Loading</Loader>
                    </Dimmer>
                }
            </>
        )
    }
}

SingleStatistic.propTypes = {
    type: PropTypes.string,
    apiUrl: PropTypes.string
}

export default SingleStatistic;