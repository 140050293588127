import React, {Fragment} from 'react';
import {Menu, Icon, Divider, Label, Message, Form, Dropdown, Segment, Button} from 'semantic-ui-react'
import {addFilter, editFilter, removeFilter} from "../../../actions";
import {connect} from "react-redux";

class Filters extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filterErrorMessage: null,
            filter: {},
            edit: -1,
            showCreate: false,
            ignoreValueOperators: ['in', 'nin', 'true', 'false'],
            hidden: false,
            operators: [],
            orFilters: [],
            validOperators: {
                '=': '=',
                '!=': '!=',
                '>': '>',
                '>=': '>=',
                '<': '<',
                '<=': '<=',
                'contains': 'Contains',
                'begins_with': 'Begins With',
                'ends_with': 'Ends With',
                'false': 'false',
                'true': 'true',
                'in': 'true',
                'nin': 'false',
                'arrin': 'flagged'
            },
            comparisonTypes: {
                'list_bool': ['in', 'nin'],
                'number': ['=', '!=', '>', '>=', '<', '<='],
                'bool': ['false', 'true'],
                'date': ['=', '!=', '>', '>=', '<', '<=', 'contains'],
                'string': ['=', '!=', 'contains', 'begins_with', 'ends_with'],
                'list': ['contains']
            },
            flaggedOnly: this.props.prospectList && this.props.prospectList.filters ? this.props.prospectList.filters.filter(f => f.key === 'org_id' && f.operator === 'arrin').length > 0 : false
        }
    }

    getColumnByKey = (key) => {
        if (this.props.availableColumns[key] || key === 'org_id') {

            return key === 'org_id' ? 'Organization' : this.props.availableColumns[key];
        } else {
            console.log('Invalid filter name detected')
            const index = this.props.prospectList.filters.findIndex(f => f.key === key);
            this.props.removeFilter(index)
            return {name: ''}
        }
    }

    handleColumnSelect = (e, {value}) => {
        const filter = this.state.filter || {}
        filter.key = value
        const dataType = this.props.availableColumns[value].type
        this.setState({
            filter,
            operators: this.state.comparisonTypes[dataType].map(op => ({
                key: op,
                value: op,
                text: this.state.validOperators[op]
            }))
        })
    }

    handleOperatorSelect = (e, {value}) => {
        const filter = this.state.filter || {}
        filter.operator = value
        this.setState({
            filter
        })
    }

    handleValueChange = (e, {value}) => {
        const filter = this.state.filter || {}
        filter.value = value
        this.setState({
            filter
        })
    }

    handleSave = () => {
        if (this.state.filter.key && this.state.filter.operator && this.state.filter.key.length > 0 && this.state.filter.operator.length > 0) {
            if (!this.state.ignoreValueOperators.includes(this.state.filter.operator) && (!this.state.filter.value || !this.state.filter.value.length > 0)) {
                this.setState({
                    filterErrorMessage: 'Please enter a value.'
                })
                return;
            }
            if (this.state.edit > -1) {
                this.props.editFilter(this.state.edit, this.state.filter)
                this.setState({
                    filterErrorMessage: null,
                    filter: {},
                    edit: -1,
                    showCreate: false,
                    operators: []
                })
            } else {
                this.props.addFilter({
                    ...this.state.filter
                })
                this.setState({
                    filterErrorMessage: null,
                    filter: {},
                    showCreate: false,
                    operators: []
                })
            }
        } else {
            this.setState({
                filterErrorMessage: 'Please select a column and operator.'
            })
        }
    }

    handleFlaggedOnlyToggle = () => {
        if (this.props.prospectList.filters.filter(f => f.key === 'org_id' && f.operator === 'arrin').length > 0) {
            const index = this.props.prospectList.filters.findIndex(f => f.key === 'org_id' && f.operator === 'arrin');
            console.log(index)
            this.props.removeFilter(index)
            this.setState({
                flaggedOnly: false
            })
        } else {
            this.props.addFilter({
                operator: 'arrin',
                key: 'org_id',
                value: this.props.prospectInteraction.flagged
            })
            this.setState({
                flaggedOnly: true
            })

        }

    }

    handleChainingFlip = (index) => {
        console.log('Flip: ', index)
        const {filters} = this.props.prospectList

        if (filters[index].or) {
            console.log('Remove')
            this.props.editFilter(index, {
                ...filters[index],
                or:false
            })
        } else {
            console.log('Add')
            this.props.editFilter(index, {
                ...filters[index],
                or:true
            })
        }

    }

    getFilterColor = (index) => {
        const validFilterOrColors = ['blue', 'orange', 'teal', 'purple', 'yellow', 'olive', 'violet', 'green',]
        const {filters} = this.props.prospectList
        let off = 0
        let colors = {}
        const orFilters = filters.map((f, i)=>({or: f.or, index: i})).filter(f=>f.or).map(f=>f.index).sort()
        for (const [i, v] of orFilters.entries()) {
            if (i === 0) {
                colors[v - 1] = validFilterOrColors[off]
                colors[v] = validFilterOrColors[off]
                off++
            } else {
                if (orFilters.includes(v - 1)) {
                    colors[v] = validFilterOrColors[off - 1]
                } else {
                    colors[v - 1] = validFilterOrColors[off]
                    colors[v] = validFilterOrColors[off]
                    off++
                }
            }
        }
        return colors[index]
    }

    render() {
        return (
            <Fragment>
                {this.props.prospectInteraction && this.props.prospectInteraction.flagged.length > 0 ?
                    <Icon link name="flag" circular inverted size='small'
                          color={this.state.flaggedOnly ? 'green' : 'grey'}
                          style={{position: 'absolute', right: '7.2em', top: '1em'}}
                          onClick={() => this.handleFlaggedOnlyToggle()}/>
                    : null
                }
                <Icon link name="add" circular inverted size='small' color='green'
                      style={{position: 'absolute', right: '4.7em', top: '1em'}}
                      onClick={() => this.setState({
                          showCreate: true,
                          hidden: false
                      })}/>
                {/*{this.state.showCreate === false && this.props.prospectList.filters.length === 0 ?*/}
                {/*    <Label basic color='green' pointing='right' style={{*/}
                {/*        position: 'absolute',*/}
                {/*        right: '5.7em',*/}
                {/*        top: '1em',*/}
                {/*        zIndex: 100*/}
                {/*    }}>Create</Label>*/}
                {/*    : null}*/}
                <Label color='grey'>{this.props.prospectList.filters.length}</Label>
                <Menu.Header as='h4' style={{cursor: 'pointer'}}
                             onClick={() => this.setState({
                                 hidden: !this.state.hidden
                             })}>Filters</Menu.Header>
                {!this.state.hidden ?
                    <Fragment>
                        {this.state.filterErrorMessage ?
                            <Message negative>
                                {this.state.filterErrorMessage}
                            </Message>
                            : null}
                        {this.state.showCreate ?
                            <Segment inverted color='grey'>
                                <Form fluid inverted size='tiny'>
                                    <Form.Field>
                                        <label>Column</label>
                                        <Dropdown
                                            search
                                            value={this.state.filter && this.state.filter.key ? this.state.filter.key : 'Column'}
                                            selection
                                            fluid
                                            onChange={(e, d) => this.handleColumnSelect(e, d)}
                                            options={this.props.availableColumns ? Object.keys(this.props.availableColumns).map(k => ({
                                                key: this.props.availableColumns[k].key,
                                                text: this.props.availableColumns[k].name,
                                                value: this.props.availableColumns[k].key
                                            })) : []}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Operator</label>
                                        <Dropdown
                                            value={this.state.filter && this.state.filter.operator ? this.state.filter.operator : null}
                                            selection
                                            fluid
                                            onChange={(e, d) => this.handleOperatorSelect(e, d)}
                                            options={this.state.operators}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Value</label>
                                        <Form.Input
                                            disabled={this.state.ignoreValueOperators.includes(this.state.filter.operator)}
                                            value={this.state.filter.value || ''}
                                            onChange={(e, d) => this.handleValueChange(e, d)}/>
                                    </Form.Field>
                                    <Form.Group widths='equal'>
                                        <Form.Button size='tiny'
                                                     color='green'
                                                     onClick={() => this.handleSave()}>{this.state.edit > -1 ? 'Update' : 'Save'}</Form.Button>
                                        <div style={{textAlign: 'right'}}>
                                            <Form.Button size='tiny'
                                                         onClick={() => this.setState({
                                                             filter: {},
                                                             edit: -1,
                                                             showCreate: false,
                                                             operators: [],
                                                             filterErrorMessage: null
                                                         })}>Cancel</Form.Button>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Segment>
                            : null}
                        {
                            this.props.prospectList.filters.length > 0 ?
                                this.props.prospectList.filters.map((filter, i) => {
                                        if (filter.key === 'org_id') {
                                            return (
                                                <Fragment>
                                                    {i !== 0 ?
                                                        <Divider horizontal
                                                                 style={{fontSize: '0.8em'}}>and</Divider>
                                                        : null
                                                    }
                                                    <Segment key={i} style={{padding: '0.5em 0.5em 0 0.5em'}}>
                                                        <Label.Group>
                                                            <Label color='green' size='small'>
                                                                Flagged
                                                            </Label>

                                                        </Label.Group>
                                                    </Segment>
                                                </Fragment>)
                                        } else {
                                            const isOr = filter.or || (this.props.prospectList.filters[i+1] && this.props.prospectList.filters[i+1].or)
                                            return (
                                                <Fragment>
                                                    {i !== 0 ?
                                                        <Divider horizontal
                                                                 style={{fontSize: '0.8em', cursor:'pointer'}}
                                                                 onClick={() => this.handleChainingFlip(i)}>{ !filter.or ?
                                                            <Fragment><span style={{fontSize: '1.2em'}}>AND</span> / <span style={{opacity:0.5}}>OR</span></Fragment>
                                                            :
                                                            <Fragment><span style={{opacity:0.5}}>AND</span> / <span style={{fontSize: '1.2em'}}>OR</span> </Fragment> }</Divider>
                                                        : null
                                                    }
                                                    <Segment key={i} style={{padding: '0.5em 0.5em 0 0.5em'}}
                                                             color={this.state.edit !== undefined && this.state.edit === i ? 'red' : isOr ? this.getFilterColor(i) : null}>
                                                        <Icon name="close" color='red' style={{
                                                            position: 'absolute',
                                                            top: '3px',
                                                            right: 0,
                                                            zIndex: 100
                                                        }}
                                                              onClick={() => {
                                                                  this.props.removeFilter(i)
                                                                  this.setState({
                                                                      filter: {},
                                                                      edit: -1,
                                                                      showCreate: false,
                                                                      operators: []
                                                                  })
                                                              }
                                                              }
                                                        />
                                                        <Icon name="pencil" color='black' size="" style={{
                                                            position: 'absolute',
                                                            top: '3px',
                                                            right: '1.5em',
                                                            zIndex: 100
                                                        }}
                                                              onClick={() => {
                                                                  this.handleColumnSelect(null, {value: filter.key})
                                                                  this.handleOperatorSelect(null, {value: filter.operator})
                                                                  this.handleValueChange(null, {value: filter.value})
                                                                  this.setState({
                                                                      showCreate: true,
                                                                      edit: i
                                                                  })
                                                              }
                                                              }
                                                        />
                                                        <Label.Group>
                                                            <Label color='teal' size='small'>
                                                                {this.getColumnByKey(filter.key).name}
                                                            </Label>
                                                            <Label
                                                                color={this.state.ignoreValueOperators.includes(filter.operator) ? 'black' : 'grey'}
                                                                size='small'>
                                                                {this.state.validOperators[filter.operator]}
                                                            </Label>
                                                            {this.state.ignoreValueOperators.includes(filter.operator) ?
                                                                null :
                                                                <Label color='black' size='small'>
                                                                    {filter.value}
                                                                </Label>
                                                            }

                                                        </Label.Group>
                                                    </Segment>
                                                </Fragment>
                                            )
                                        }
                                    }
                                ) : null
                        }
                    </Fragment> : null}
            </Fragment>
        )
    }
}

function mapStateToProps({prospectList, prospectInteraction}) {
    return {prospectList, prospectInteraction};
}

const mapDispatchToProps = {
    addFilter, removeFilter, editFilter
}
export default connect(mapStateToProps, mapDispatchToProps)(Filters)