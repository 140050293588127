import React from 'react'
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;


class MapComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: props.lng || 5,
            lat: props.lat || 34,
            zoom: props.lat ? 12 : 2
        };
    }

    componentDidMount() {
        const {lat, lng} = this.state
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/light-v9',
            center: [lat, lng],
            zoom: this.state.zoom
        });


        if (lat && lng) {
            const marker = new mapboxgl.Marker({
                color: '#f8b000',
            })
                .setLngLat([lng, lat])
                .addTo(map);
        }
    }

    render() {
        return (
            <div ref={el => this.mapContainer = el} style={{height: '100%', width: '100%'}}/>
        )
    }
}

export default MapComponent