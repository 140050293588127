import callApi from "./callApi";

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


export const setUserState = async (data) => {
    if (data) {
        try {
            await callApi('db/userState', 'set', {state: data, timestamp: +Date.now()});
            console.log('Set User State')
            return true;
        } catch (e) {
            console.error('Failed to set user state')
        }
    }
    return null;
}

export const makeNumberPretty = (num, precision) => {
    return parseInt(num).toLocaleString(
        undefined,
        {minimumFractionDigits: precision}
    );
}


export const decodeQueryString = (queryString) => {

    if (!queryString)
    {

        queryString = window.location.search;
    }

    let queryStrings = {};
    // ? should be decoded
    if (queryString.indexOf('?') > -1) {
        queryString = queryString.replace('?', '');
    }

    queryString.split('&').forEach(pair => {

        // let [key, value] = pair.split('='); // key="selectedDate" value="2018"
        // TODO check length?

        let res = pair.split('=');

        queryStrings[(res[0] || '').toString()] = decodeURIComponent(res[1] || '');

    });

    return queryStrings;
}