import React from 'react'
import {Placeholder, Table} from "semantic-ui-react";

class LoadingTable extends React.Component {
    render() {
        const {rows, columns} = this.props
        const lengths = ['full', 'very long', 'long', 'medium', 'short', 'very short']
        return (
            <Table definition sortable celled striped selectable>
                <Table.Header>
                    {Array.apply(null, Array(columns))
                        .map(x => <Table.HeaderCell><Placeholder>
                            <Placeholder.Header>
                                <Placeholder.Line length={lengths[Math.floor(Math.random() * 6)]}/>
                            </Placeholder.Header>
                        </Placeholder></Table.HeaderCell>)}
                </Table.Header>
                <Table.Body>
                    {Array.apply(null, Array(rows))
                        .map(x => <Table.Row>
                            {Array.apply(null, Array(columns))
                                .map(x => <Table.Cell>
                                    <Placeholder>
                                        <Placeholder.Line length={lengths[Math.floor(Math.random() * 6)]}/>
                                    </Placeholder>
                                </Table.Cell>)}
                        </Table.Row>)}
                </Table.Body>
            </Table>
        )
    }
}

export default LoadingTable