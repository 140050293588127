
import React from 'react'; // eslint-disable-line no-unused-vars

import {ForgotPassword} from 'aws-amplify-react';

import AuthWrapper from './components/AuthWrapper';

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _AmplifyUI = require('aws-amplify-react/dist/AmplifyUI');

var _react2 = _interopRequireDefault(React);

var _core = require('@aws-amplify/core');

var _AmplifyTheme = require('aws-amplify-react/dist/AmplifyTheme');

var _AmplifyTheme2 = _interopRequireDefault(_AmplifyTheme);

class CustomForgotPassword extends ForgotPassword
{

    constructor(props)
    {

        super(props);

        this.superShowComponent = this.showComponent;

        this.showComponent = (theme) =>
        {

            if (window.document)
            {

                window.document.title = 'Forgot Password | ' + (process.env['REACT_APP_SITE_TITLE'] || 'Wheelhouse Boilerplate App');
            }
            // eslint-disable-next-line
            var _this4 = this;

            var _props = this.props,
                // eslint-disable-next-line
                authState = _props.authState,
                hide = _props.hide;

            if (hide && hide.includes(ForgotPassword)) {
                return null;
            }

            return (
                <AuthWrapper>

                    {
                        _react2.default.createElement(
                            _AmplifyUI.FormSection,
                            { theme: theme },
                            _react2.default.createElement(
                                _AmplifyUI.SectionHeader,
                                { theme: theme },
                                _core.I18n.get('Forgot Password')
                            ),
                            _react2.default.createElement(
                                _AmplifyUI.SectionBody,
                                null,
                                this.state.delivery ? this.submitView() : this.sendView()
                            )
                        )
                    }

                </AuthWrapper>
            );
        };

        // this.superComponentDidMount = this.componentDidMount;
        //
        // this.componentDidMount = () =>
        // {
        //
        //     if (typeof this.superComponentDidMount === 'function')
        //     {
        //
        //         this.superComponentDidMount();
        //     }
        //
        //     if (window.document)
        //     {
        //
        //         window.document.title = 'Forgot Password | ' + (process.env['REACT_APP_SITE_TITLE'] || 'Wheelhouse Boilerplate App');
        //     }
        // };
    }

    sendView()
    {
        var _this4 = this;
        var theme = this.props.theme || _AmplifyTheme2.default;
        return _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(_AmplifyUI.InputRow, {
                autoFocus: true,
                placeholder: _core.I18n.get('Username'),
                theme: theme,
                key: 'username',
                name: 'username',
                onChange: this.handleInputChange
            }),
            _react2.default.createElement(
                _AmplifyUI.Link,
                { theme: theme, onClick: function onClick() {
                        return _this4.changeState('signIn');
                    } },
                _core.I18n.get('Back to Sign In')
            ),
            _react2.default.createElement(
                _AmplifyUI.ButtonRow,
                { theme: theme, onClick: this.send },
                _core.I18n.get('Send Code')
            )
        );
    }

    submitView()
    {
        var _this4 = this;
        var theme = this.props.theme || _AmplifyTheme2.default;
        return _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(_AmplifyUI.InputRow, {
                placeholder: _core.I18n.get('Code'),
                theme: theme,
                key: 'code',
                name: 'code',
                autoComplete: 'off',
                onChange: this.handleInputChange
            }),
            _react2.default.createElement(_AmplifyUI.InputRow, {
                placeholder: _core.I18n.get('New Password'),
                theme: theme,
                type: 'password',
                key: 'password',
                name: 'password',
                onChange: this.handleInputChange
            }),
            _react2.default.createElement(
                _AmplifyUI.Link,
                { theme: theme, onClick: function onClick() {
                        return _this4.changeState('signIn');
                    } },
                _core.I18n.get('Back to Sign In')
            ),
            _react2.default.createElement(
                _AmplifyUI.ButtonRow,
                { theme: theme, onClick: this.submit },
                _core.I18n.get('Submit')
            )
        );
    }
}

export default CustomForgotPassword;