import React, {Fragment} from 'react';
import {Grid} from "semantic-ui-react";
import AccountSettings from "./components/AccountSettings";
import TopMenu from "./components/topMenu";
import {Helmet} from "react-helmet/es/Helmet";

class Settings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    renderTool = () => {
        const toolName = 'account-settings'
        switch (toolName) {
            case "account-settings": {
                return <AccountSettings/>
            }
            default:
                return ''
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Settings | Mozaic</title>
                </Helmet>
                <Grid container>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <TopMenu/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            {this.renderTool()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        )
    }
}

export default Settings