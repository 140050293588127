import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Dayjs from 'dayjs';

import {Grid, Header, List, Segment, Table} from 'semantic-ui-react';

/**
 * Component runs a api call based on the current, then displays the list.
 */
class ProspectProfileSummary extends React.Component {
    constructor(props) {
        super(props);

        const sanexenKeys = {
            name: 'Water System Name',
            main_url: 'Main Url',
            phone_number: 'Phone Number',
            email_addr: 'Email Address',
            admin_name: 'Administrator',
            primary_pwsid: 'Water System ID',
            city_name: 'Primary City',
            primary_state: 'State',
            address_line1: 'Street Address',
            address_line2: 'Street Address 2',
            zip_code: 'ZIP Code',
            cities_served: 'Cities Served',
            counties_served: 'Counties Served',
            population_served_count: 'Population Served',
            service_connections_count: 'Service Connections',
            epa_region: 'EPA Region',
            gw_sw_code: 'Water Type',
            is_school_or_daycare_ind: 'School Provider',
            // owner_type_code: "",
            // pop_cat_2_code: "",
            // pop_cat_3_code: "",
            // pop_cat_4_code: "",
            // pop_cat_5_code: "",
            // pop_cat_11_code: "",
            // primacy_agency_code: "",
            // primacy_type: "",
            // primary_city: "",
            // primary_industry: "",
            // primary_phone_number: "",
            // primary_pwsid: "",
            // primary_source_code: "",
            // pws_activity_code: "",
            // pws_name: "Water System Name",
            // pws_type_code: "",
            // pwsid: "",
            // record_creation: "",
            // state_code: "",
            // submission_status_code: "",
        };

        this.state = {
            dataKeysToShow: sanexenKeys
        };


        const getDateHandler = key => item => {
            let result = 'N/A';
            const dayjsText = Dayjs((item || {})[key]);

            if (dayjsText.isValid()) result = dayjsText.format('MM-DD-YYYY');

            return result;
        };

        // NOTE: this is gross, but with the JSX we're kinda forced
        this.specialHandlers = {
            'main_url': item => {
                if (!item.main_url) {

                    return (
                        <Fragment>
                            N/A
                        </Fragment>
                    );
                }

                return (
                    // Modified to prefix http:// to the url in absense of protocol, http to https upgrades have a lower error-rate
                    // in comparison to sites with no https configured.
                    <a href={(`${item.main_url}`.trim().indexOf('http://') !== 0 && `${item.main_url}`.trim().indexOf('https://') !== 0 ? 'http://' : '') + `${item.main_url}`.trim()}
                        target={'_blank'}>
                        {item.main_url}
                    </a>
                );
            },
            'email_address': item => {
                let retval = null;

                if (item && item['email_address']) {
                    retval = (
                        <a href={`mailto:${item['email_address']}`}>
                            {item['email_address']}
                        </a>
                    );
                }

                return retval;
            },
            'name': item => (
                <Link to={`/prospect/profile/?id=${encodeURIComponent(item.org_id)}`} onClick={() => {
                    // shouldn't be an issue in general.  only interacts if checkedOrgs exists.
                    if (this.state.checkedOrgs && Object.keys(this.state.checkedOrgs).length) {
                        localStorage.setItem('checkedOrgs', JSON.stringify(this.state.checkedOrgs));
                    }
                }}>
                    {item.name || 'N/A'}
                </Link>
            ),
            'open_date': getDateHandler('open_date'),
            'issuance_date': getDateHandler('issuance_date'),
            'abate_date': getDateHandler('abate_date'),
        };
    }

    componentDidMount() {
        console.log('componentDidMount in ProspectProfileSummary, state: ', this.state);
    }

    chunkify(a, n, balanced) {

        if (n < 2) {return [a];}

        var len = a.length,
            out = [],
            i = 0,
            size;

        if (len % n === 0) {
            size = Math.floor(len / n);
            while (i < len) {
                out.push(a.slice(i, i += size));
            }
        } else if (balanced) {
            while (i < len) {
                size = Math.ceil((len - i) / n--);
                out.push(a.slice(i, i += size));
            }
        } else {

            n--;
            size = Math.floor(len / n);
            if (len % size === 0) {size--;}
            while (i < size * n) {
                out.push(a.slice(i, i += size));
            }

            out.push(a.slice(size * n));

        }

        return out;
    }

    render() {
        const maxItemsPerCol = 5;
        const {prospect} = this.props;
        const {dataKeysToShow} = this.state;
        let keyChunks = [];
        if (prospect && dataKeysToShow) {
            const keysToShow = Object.keys(dataKeysToShow).filter(item => this.props.prospect[item]);
            if (keysToShow.length > maxItemsPerCol) {
                // const slices = Math.ceil(keysToShow.length / maxItemsPerCol)
                keyChunks = this.chunkify(keysToShow, maxItemsPerCol, true);
            } else {
                keyChunks.push(keysToShow);
            }
            // console.log(keys)
        }

        return (
            <Grid columns={keyChunks.length}>
                {/* <Header as='h3'>Organization Information</Header>*/}
                {/* Loops over the data keys and displays each type. */}
                {keyChunks.length > 0 ?
                    keyChunks.map(keyChunk => (
                        <Grid.Column>
                            <List relaxed size='medium'>
                                {keyChunk.map(key => {
                                    let returnValue = null;

                                    if (typeof this.specialHandlers[key] === 'function' && key !== 'name') {

                                        returnValue = this.specialHandlers[key](this.props.prospect);
                                    } else {

                                        returnValue = (
                                            <Fragment>
                                                {this.props.prospect[key]}
                                            </Fragment>
                                        );
                                    }

                                    return <List.Item>
                                        <List.Header>
                                            {dataKeysToShow[key]}
                                        </List.Header>
                                        {returnValue}
                                    </List.Item>;
                                })}
                            </List>
                        </Grid.Column>
                    )) :
                    null
                }
            </Grid>
        );
    }
}

ProspectProfileSummary.propTypes = {
    prospect: PropTypes.object
};

export default ProspectProfileSummary;