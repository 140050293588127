import React, { Component } from 'react'

import PropTypes from 'prop-types';
import { Feed, Segment, Icon, Grid } from 'semantic-ui-react';


class NewsFeed extends React.Component {
    constructor(props) {
        super(props)
    }

    // This will create a post per received data point
    handleMediaPost = (media) => {
        if (media.length <= 4) {
            return (
                <a>
                    <img src={media[0].url} alt={media[0].alt} />
                </a>
            )
        }
        return (media.map((singleMedia, i) => (
            <Grid.Column key={i}>
                <Segment style={{
                    height: 50,
                    width: 50,
                    position: 'relative',
                    display: 'inline-block',
                    overflow: 'hidden',
                    margin: 0,
                }}>
                    <img
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            minHeight: '100%',
                            minWidth: '100%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        src={singleMedia.url}
                        alt={singleMedia.alt}
                    />
                </Segment>
            </Grid.Column>
        ))
        )
    }


    handleGeneratingFeed = () => {
        const { data } = this.props;
        return data.map((dataset, i) => (
            <Feed.Event style={{ color: 'white' }} key={i}>
                <Feed.Label><img src={dataset.imageIcon} alt='Picture of someone'></img></Feed.Label>
                <Feed.Content style={{ color: 'white' }} >
                    <Feed.Summary style={{ color: 'white' }}>{dataset.title}
                        {dataset.time ? <Feed.Date style={{ color: 'white' }} >{dataset.time} ago</Feed.Date> : null}
                    </Feed.Summary>
                    <Feed.Extra style={{ color: 'white' }}>{dataset.body}
                        {dataset.media ?
                            <Segment inverted style={{ width: '50%', overflow: 'auto' }}>
                                <Grid stackable>
                                    <Grid.Row >

                                        {this.handleMediaPost(dataset.media)}

                                    </Grid.Row>
                                </Grid>
                            </Segment> :
                            null}
                    </Feed.Extra>
                    {dataset.url ? <Feed.Meta ><a style={{ color: 'white' }} target='_blank' href={dataset.url}><Icon style={{ color: 'white' }} name='world'></Icon>{dataset.url}</a></Feed.Meta> : null}
                </Feed.Content>

            </Feed.Event>
        ))
    }

    render() {
        const { data } = this.props;
        return (
            <>
                <Feed  >
                    {this.handleGeneratingFeed()}
                </Feed>

            </>
        )
    }
}

NewsFeed.propTypes = {
    type: PropTypes.string,
}

export default NewsFeed;