import React, {Fragment} from 'react'
import {Button, Table} from "semantic-ui-react";
import {makeNumberPretty} from "../../../../../util/lib";

class ListComponent extends React.Component{
    constructor(props) {
        super(props)
        this.state={
            showAll: false
        }
    }

    handleColTransform(transform, value) {
        if (value) {
            switch (transform) {
                case 'parseInt': {
                    return parseInt(value)
                }
                case 'date' : {
                    const date = new Date(Date.parse(value));
                    return `${date.getMonth() + 1}/${date.getDay() + 1}/${date.getFullYear()}`;
                }
                default: {
                    return value;
                }
            }
        } else return value;
    }

    render() {
        return (
            <Fragment>
                <Table basic='very' celled stacking striped compact fixed>
                    <Table.Header>
                        {this.props.columns.map(col => <Table.HeaderCell>{col.label}</Table.HeaderCell>)}
                    </Table.Header>
                    {this.props.data && this.props.data.length ?
                        this.props.data.slice(0, this.state.showAll ? this.props.data.length : this.props.showCount || 10).map((item, index) => (
                            <Table.Row>
                                {this.props.columns.map(col => {
                                    const value = item[col.key];
                                    return (
                                        <Table.Cell singleLine={false} style={{wordWrap: 'break-word'}}>
                                            {col.transform && col.transform === 'link' ? <a target={"blank"}
                                                                                            href={`https://${value}`}>{value}</a> : `${col.monetary ? '$ ' : ''}${(col.prettify ? makeNumberPretty(value) : col.transform ? this.handleColTransform(col.transform, value) : value) || 'N/A'}`}
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                        )) :
                        null}
                </Table>
                {this.props.data && this.props.data.length > this.props.showCount ?

                    <Button
                        fluid
                        size="small"
                        basic
                        onClick={() => {
                            this.setState({showAll: !this.state.showAll});
                        }}>
                        {`Show ${this.state.showAll ? 'Less' : 'More'}`}
                    </Button>
                    : null}
            </Fragment>
        )
    }
}

export default ListComponent