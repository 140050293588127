import React, { Component } from 'react'

import PropTypes from 'prop-types';
import { Statistic, Loader, Dimmer } from 'semantic-ui-react';
import callApi from '../../../util/callApi';

class StatGroup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true,
        }
    }


    componentDidMount() {
        if (this.state.data.length < 1) {
            callApi(this.props.apiUrl, 'dashboard').then(data => this.setState({ data: data, isLoading: false }))
        }
    }

    handleGeneratingStatistic() {

        return this.state.data.map((dataset, i) => (
            <Statistic style={{ marginLeft: 'auto', marginRight: 'auto' }} key={i}>

                <Statistic.Value>{dataset.value}</Statistic.Value>
                <Statistic.Label>{dataset.label}</Statistic.Label>
            </Statistic>
        ))
    }



    handleListDirection = (data) => (data && data.length > 2 ? true : false)

    handleStatisticSize = (data) => {
        if (data) {
            if (data.length <= 2) {
                return 'large';
            } else if (data.length > 2 && data.length <= 5) {
                return 'small';
            } else if (data.length > 5 && data.length <= 8) {
                return 'tiny'
            } else {
                return 'mini'
            }
        } else {
            return 'mini'
        }

    }

    render() {
        const { data, isLoading } = this.state;
        return (
            <>
                {!isLoading ?
                    <Statistic.Group style={{ marginLeft: 'auto', marginRight: 'auto', border: `1px solid ${this.props.color}` }} inverted horizontal={this.handleListDirection(data)} size={this.handleStatisticSize(data)} widths={data && data.length ? data.length : 4}>
                        {this.handleGeneratingStatistic()}
                    </Statistic.Group> :

                    <Dimmer active>
                        <Loader>Loading</Loader>
                    </Dimmer>

                }
            </>
        )
    }
}

StatGroup.propTypes = {
    type: PropTypes.string,
    apiUrl: PropTypes.string
}

export default StatGroup;