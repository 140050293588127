import { Auth } from 'aws-amplify';

const callApi = async (url, action, data = {}) => {
    const currentSession = await Auth.currentSession();
    const auth = ((currentSession || {})['idToken'] || {})['jwtToken'];

    let env = 'prod';
    if (process.env['REACT_APP_ENV'] && ['dev', 'prod', 'qa'].includes(process.env['REACT_APP_ENV'])) {
        env = process.env['REACT_APP_ENV'];
    }

    // Assumes his code will fail without proper token.
    if (typeof data !== 'string') {
        try {
            data = JSON.stringify({ data });
        } catch (e) {
            console.error('error getting json from data object', e, 'error');
            throw new Error(e, 'json encode failed');
        }
    }

    try {
        const resp = await fetch(process.env['REACT_APP_API_PREFIX'] + url, {
            'method': 'POST',
            'headers': { 'Authorization': auth, 'action': action, 'env': env },
            'body': data
        });
        if (resp.ok) {
            const respJson = await resp.json();
            if (Array.isArray(respJson)) {
                return respJson
            } else {
                return { ...respJson, ...{ statusCode: resp.status } }
            }

        } else {
            // NOTE: this catches errors
            return { data: {}, statusCode: resp.status }
        }
    } catch (err) {
        console.error('error in callApi: ', err);
        throw new Error(((err || {}).response || {}).data || err, err);
    }
};

export default callApi;

// async callApi(url, action, data = {}) {
//     this.setState({isLoading: true})
//     const currentSession = await Auth.currentSession();
//     const Authentication = ((currentSession || {})['idToken'] || {})['jwtToken'];
//
//     // Assumes his code will fail without proper token.
//     if (typeof data !== 'string') {
//         try {
//             data = JSON.stringify(data);
//         } catch (e) {
//             this.log('error getting json from data object', e, 'error');
//             throw new Error(e, 'json encode failed');
//         }
//     }
//
//     try {
//         const resp = await (await fetch(process.env['REACT_APP_API_PREFIX'] + url, {
//             'method': 'POST',
//             'headers': {'Authorization': Authentication, 'action': action},
//             'body': data
//         })).json();
//
//         // if there's a .data use it or whatever
//         this.setState({isLoading: false})
//         return resp.data || resp;
//     } catch (err) {
//         console.error('error in callApi: ', err);
//         throw new Error(((err || {}).response || {}).data || err, err);
//     }
//
// }