import React, {Fragment} from 'react';
import {
    Button,
    Card,
    CardGroup,
    Checkbox,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    List,
    Placeholder,
    Segment
} from "semantic-ui-react";
import callApi from "../../../../util/callApi";
import DeleteModal from "./components/deleteModal";
import ErrorBlock from "../../../../components/ErrorBlock";
import RegexColorizer from 'regex-colorizer'

class KeyTermManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            terms: [],
            term: {},
            isLoading: true,
            showCreate: false,
            createTerms: 1,
            edit: false,
            showRegex: false
        }
    }

    async componentDidMount() {
        RegexColorizer.addStyleSheet();
        try {
            const terms = await callApi('db/keyTerms', 'read', {})
            // console.log(terms)
            this.setState({terms, ogTerms: terms, isLoading: false})
        } catch (e) {
            console.error(e)
            this.setState({isLoading: false, error: `Unable to load keyTerms: ${JSON.stringify(e)}`})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        RegexColorizer.colorizeAll();
    }

    handleSearchChange(e, {value}) {
        this.setState({
            terms: this.state.ogTerms.filter(t => t.name.includes(value))
        })
    }

    resetCreateState() {
        this.setState({
            createTerms: 1,
            showCreate: false,
            edit: false,
            editIndex: null,
            term: {}
        })
    }

    async handleSave() {
        if (this.state.term.keywords) {
            const alert = this.state.term.keywords.reduce((acc, cur) => (cur.length === 0), false)
            if (alert) {
                alert('Keywords must not be empty')
                return
            }
        }
        if (!this.state.term.name || this.state.term.name.length === 0) {
            alert('Term must have a name')
            return
        }
        this.setState({
            isLoading: true
        })
        try {
            const {term} = this.state
            const cleanTerm = {
                name: term.name,
                type: term.keywords ? 'keyword' : 'regex',
                enabled: true,
                caseSensitive: term.caseSensitive || false
            }
            if (cleanTerm.type === 'keyword') {
                cleanTerm.keywords = term.keywords
            } else {
                cleanTerm.regex_expression = ''
                cleanTerm.regex_flags = ''
            }
            if (this.state.edit) {
                try {
                    const res = await callApi('db/keyTerms', 'update', {term: cleanTerm, id: this.state.editIndex})
                    if (res.statusCode === 400) {
                        alert('Please Use a Unique Name')
                        this.setState({isLoading: false})
                        return;
                    }
                } catch (e) {
                    console.log(e)
                }
            } else {
                try {
                    const res = await callApi('db/keyTerms', 'create', {term: cleanTerm})
                    if (res.statusCode === 400) {
                        alert('Please Use a Unique Name')
                        this.setState({isLoading: false})
                        return;
                    }
                } catch (e) {
                    console.log(e)
                }
            }
            this.resetCreateState()
            await this.componentDidMount()
        } catch (e) {
            // if(e)
            console.log(e)
            this.setState({isLoading: false})
        }

    }


    async handleDelete(termId) {
        this.setState({
            isLoading: true
        })
        try {
            const res = await callApi('db/keyTerms', 'delete', {id: termId})
        } catch (e) {
            console.log(e)
        }
        await this.componentDidMount()
    }

    render() {
        return (
            <Fragment>
                <Header as='h1' textAlign='center'>Key Term Manager</Header>
                <ErrorBlock error={this.state.error}/>
                <Grid centered>
                    <Grid.Row columns={2}>
                        <Grid.Column textAlign='left'>
                            <Button disabled={this.state.showCreate || this.state.error} color='green'
                                    onClick={() => this.setState({showCreate: true})}>
                                <Icon name='plus'/>
                                Create
                            </Button>
                            <Checkbox toggle label={"RegEx"} style={{marginLeft: '1em'}} onClick={()=>this.setState({showRegex: !this.state.showRegex})}/>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <Input disabled={this.state.showCreate || this.state.error} icon='search'
                                   onChange={(e, v) => this.handleSearchChange(e, v)}/>
                        </Grid.Column>
                    </Grid.Row>
                    {this.state.showCreate ?
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Segment attached={'top'} loading={this.state.isLoading}>
                                    <Grid columns={2}>
                                        <Grid.Column>
                                            <Form>
                                                <Form.Field>
                                                    <label>Name</label>
                                                    <Input value={this.state.term.name}
                                                           onChange={(e, v) => this.setState({
                                                               ...this.state,
                                                               term: {
                                                                   ...this.state.term,
                                                                   name: v.value
                                                               }
                                                           })}/>
                                                </Form.Field>
                                            </Form>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form>
                                                {Array.apply(null, Array(this.state.createTerms))
                                                    .map((x, i) =>
                                                        <Form.Group>
                                                            <Form.Field width={16}>
                                                                <label>Term {i + 1}</label>
                                                                <Input
                                                                    value={this.state.term.keywords && this.state.term.keywords[i] ? this.state.term.keywords[i] : null}
                                                                    onChange={(e, v) => {
                                                                        const keywords = this.state.term.keywords || []
                                                                        keywords[i] = v.value
                                                                        this.setState({
                                                                            ...this.state,
                                                                            term: {
                                                                                ...this.state.term,
                                                                                keywords
                                                                            }
                                                                        })
                                                                    }}/>
                                                            </Form.Field>
                                                            {/*<Form.Field width={2}>*/}
                                                            {/*    <label>Remove</label>*/}
                                                            {/*    <Button icon={"close"} color={'red'}*/}
                                                            {/*            size={"tiny"}*/}
                                                            {/*            disabled={this.state.createTerms === 1}*/}
                                                            {/*            onClick={() => {*/}
                                                            {/*                const keywords = this.state.term.keywords*/}
                                                            {/*                */}
                                                            {/*            }*/}
                                                            {/*            }/>*/}
                                                            {/*</Form.Field>*/}
                                                        </Form.Group>
                                                    )

                                                }
                                                <Button basic size='small'
                                                        disabled={this.state.createTerms >= 10}
                                                        onClick={() => this.setState({
                                                            createTerms: this.state.createTerms < 10 ? this.state.createTerms + 1 : this.state.createTerms
                                                        })}>
                                                    <Icon name='plus'/>
                                                    Add Term
                                                </Button>
                                            </Form>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                                <Segment attached={'bottom'} textAlign='center' size='tiny'>
                                    <Button color='green' onClick={() => this.handleSave()}>
                                        <Icon name='save'/>
                                        Save
                                    </Button>
                                    <Button color='black' onClick={() => this.resetCreateState()}>
                                        <Icon name='cancel'/>
                                        Cancel
                                    </Button>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        : null}
                </Grid>
                <CardGroup stackable itemsPerRow={2}>
                    {this.state.isLoading ?
                        <Fragment>
                            <Card>
                                <Card.Content>
                                    <Card.Header>
                                        <Placeholder>
                                            <Placeholder.Header>
                                                <Placeholder.Line/>
                                            </Placeholder.Header>
                                        </Placeholder>
                                    </Card.Header>
                                    <Card.Meta>
                                        <Placeholder>
                                            <Placeholder.Line/>
                                        </Placeholder>
                                    </Card.Meta>
                                    <Card.Description>
                                        <Placeholder>
                                            <Placeholder.Line/>
                                        </Placeholder>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra textAlign='center'>
                                    <Button.Group>
                                        <Button disabled basic color='black'>
                                            <Icon name='pencil'/>
                                            Edit
                                        </Button>
                                        <Button disabled basic color='red'>
                                            <Icon name='delete'/>
                                            Delete
                                        </Button>
                                    </Button.Group>
                                </Card.Content>
                            </Card>
                            <Card>
                                <Card.Content>
                                    <Card.Header>
                                        <Placeholder>
                                            <Placeholder.Header>
                                                <Placeholder.Line/>
                                            </Placeholder.Header>
                                        </Placeholder>
                                    </Card.Header>
                                    <Card.Meta>
                                        <Placeholder>
                                            <Placeholder.Line/>
                                        </Placeholder>
                                    </Card.Meta>
                                    <Card.Description>
                                        <Placeholder>
                                            <Placeholder.Line/>
                                        </Placeholder>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra textAlign='center'>
                                    <Button.Group>
                                        <Button disabled basic color='black'>
                                            <Icon name='pencil'/>
                                            Edit
                                        </Button>
                                        <Button disabled basic color='red'>
                                            <Icon name='delete'/>
                                            Delete
                                        </Button>
                                    </Button.Group>
                                </Card.Content>
                            </Card>
                        </Fragment>
                        :
                        this.state.terms.filter(t => {
                            if (this.state.editIndex) {
                                return t._id === this.state.editIndex
                            } else {
                                if(!this.state.showRegex) {
                                    return t.type === 'keyword'
                                }
                                return true
                            }
                        }).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(term =>
                            <Card color={this.state.editIndex === term._id ? 'red' : null}>
                                <Card.Content>
                                    <Card.Header>
                                        {term.name}
                                    </Card.Header>
                                    <Card.Meta>
                                        {term.type}
                                    </Card.Meta>
                                    <Card.Description>
                                        {term.regex_expression ?
                                            <code className={'regex'}>
                                                {term.regex_expression}
                                            </code>
                                            :
                                            term.keywords ?
                                                <List>
                                                    {term.keywords.map((word, i) =>
                                                        <List.Item key={i}>
                                                            <List.Content>
                                                                <Icon name='right triangle'/>
                                                                {word}
                                                            </List.Content>
                                                        </List.Item>
                                                    )}
                                                </List> :
                                                null
                                        }
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra textAlign='center'>
                                    <Button.Group>
                                        <Button
                                            basic
                                            disabled={this.state.edit}
                                            color='black'
                                            size='mini'
                                            onClick={() => this.setState({
                                                edit: true,
                                                editIndex: term._id,
                                                showCreate: true,
                                                term
                                            })}>
                                            <Icon name='pencil'/>
                                            Edit
                                        </Button>
                                        <DeleteModal handleDelete={() => this.handleDelete(term._id)}/>
                                    </Button.Group>
                                </Card.Content>
                            </Card>
                        )
                    }
                </CardGroup>
            </Fragment>
        )
    }
}

export default KeyTermManager