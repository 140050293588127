import React, { component } from 'react';
import Filters from "./Filters";
import {
    Icon,
    Label,
    Menu,
    Button,
    Grid,
    Segment,
    Dropdown,
    Message,
    Rating
} from 'semantic-ui-react';
import { removeFilter, addFilter, setColumns, setPageSize, setSort, setPageNumber, toggleMapView } from "../../../actions";
import fieldList from './fieldList'
import ExportModal from '../../../components/ExportModal';
import { debounce } from 'lodash';
import { Slider } from "react-semantic-ui-range";
import { connect } from "react-redux";

// import CsvDownloader from 'react-csv-downloader';

import * as fileDownload from 'js-file-download';

class SidebarContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            availableColumns: fieldList,
            nonShowKeyList: ['_id', 'client_name', 'id', 'org_id'],
            activeItem: null,
            filters: [],
            showSidebarGroups: {
                columns: true,
                rows: true,
                export: true
            },
            showSidebar: true,
            // selectedForExport: new Set(),
            // prospectList: [] // TODO: causes errors if it isn't set here, might cause errors if it is? Seems fine for now...
        };

    }

    handleBulkExport = async (destination) => {

        const { data, statusCode } = await this.props.handleExportProspectData(1, this.state.csvExportNum || 1000, false, destination)


        if (destination === 'csv') {
            fileDownload(data, `bulk-prospects.csv`)
        } else {
            console.log(destination)
        }

        return statusCode;

    };



    handleSelectedProspectExport = async (destination) => {
        this.setState({ loading: true })
        const { data, statusCode } = await this.props.handleExportProspectData(null, this.state.csvExportNum || 1000, true, destination)

        if (destination == 'csv') {

            fileDownload(data, `selected-prospects-${new Date().toString()}.csv`)
        } else {
            null
        }

        return statusCode;
    };

    handleThisPageExport = async (destination) => {
        this.setState({ loading: true })

        const { data, statusCode } = await this.props.handleExportProspectData(this.props.prospectList.currentPage, this.props.prospectList.pageSize, false, destination)

        if (destination === 'csv') {
            fileDownload(data, `page-prospects-${new Date().toString()}.csv`)
        } else {
            null
        }

        return statusCode;
    }

    validateColumn = (column) => {
        if (this.state.availableColumns[column]) {
            return {
                key: this.state.availableColumns[column].key,
                text: this.state.availableColumns[column].name,
                value: this.state.availableColumns[column].key
            }
        } else {
            console.log('invalid column detected')
            this.props.setColumns([])
            window.location.reload()
        }
    }
    handleColumnSelectChange = (e, { value }) => {
        if (value.length > 6) {
            this.setState({
                columnSelectError: 'Maximum of 6 Columns. Please remove one to continue.'
            })
        } else {
            const columns = value.map(value => {
                // for (const key of Object.keys(this.state.availableColumns)) {
                //     if (this.state.availableColumns[key].key === value) {
                return this.validateColumn(value)
                // }
                // }
            });
            this.props.setColumns(columns)
            this.setState({
                columnSelectError: null,
            })
        }
    }

    setPageSize = debounce((size) => {
        this.props.setPageSize(size)
    }, 250)


    render() {
        const datas = this.state.prospectList || [];
        const selectedDatas = this.state.prospectList ? this.state.prospectList.filter(prospect => this.props.selectedForExport.has(prospect.org_id)) : [];
        const { mapView } = this.props.prospectList;

        return (
            <Grid.Column tablet={16} computer={3} stretched={true} className='sidebarSticky'>
                <Segment basic loading={this.props.isLoading}>
                    <Menu vertical fluid loading>
                        {/*<Menu.Item>*/}
                        {/*    <Input icon='search' placeholder='Search...'/>*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item
                            name='mapToggle'
                        >
                            <Menu.Header as='h4' style={{ cursor: 'pointer' }}>View</Menu.Header>
                            {mapView ?
                                <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button compact onClick={() => this.props.toggleMapView(false)}>List</Button>
                                    <Button compact active>Map</Button>
                                </Button.Group>
                                :
                                <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button compact active>List</Button>
                                    <Button compact onClick={() => this.props.toggleMapView(true)}>Map</Button>
                                </Button.Group>
                            }
                        </Menu.Item>

                        <Menu.Item
                            name='filters'
                        >
                            <Filters availableColumns={{
                                'name': {
                                    key: 'name',
                                    name: 'Name',
                                    type: 'string'
                                }, ...this.state.availableColumns
                            }}
                                filters={this.props.prospectList.filters} />
                        </Menu.Item>

                        {!mapView &&
                            <div>
                                <Menu.Item
                                    name='columns'
                                >
                                    <Label
                                        color={this.props.prospectList.selectedColumns && this.props.prospectList.selectedColumns.length < 6 ? 'grey' : 'yellow'}>{this.props.prospectList.selectedColumns ? this.props.prospectList.selectedColumns.length : 0}</Label>
                                    <Menu.Header as='h4' style={{ cursor: 'pointer' }}

                                    >Columns</Menu.Header>

                                    <Menu.Menu>
                                        <Menu.Item>
                                            {this.state.columnSelectError ?
                                                <Message negative>
                                                    {this.state.columnSelectError}
                                                </Message>
                                                : null}
                                            <Dropdown
                                                placeholder=''
                                                fluid
                                                multiple
                                                search
                                                value={this.props.prospectList.selectedColumns.map(o => this.validateColumn(o.key).value)}
                                                selection
                                                onChange={(e, d) => this.handleColumnSelectChange(e, d)}
                                                options={this.state.availableColumns ? Object.keys(this.state.availableColumns).map(k => ({
                                                    key: this.state.availableColumns[k].key,
                                                    text: this.state.availableColumns[k].name,
                                                    value: this.state.availableColumns[k].key
                                                })) : []}
                                            />
                                        </Menu.Item>
                                    </Menu.Menu>

                                </Menu.Item>

                                <Menu.Item>
                                    <Label
                                        color={this.props.prospectList.pageSize >= 50 ? 'yellow' : this.props.prospectList.pageSize <= 5 ? 'yellow' : 'grey'}>{this.props.prospectList.pageSize}</Label>
                                    <Menu.Header as='h4'>Rows</Menu.Header>
                                    {this.props.prospectList.pageSizeError ?
                                        <Message negative>
                                            {this.props.prospectList.pageSizeError}
                                        </Message>
                                        : null}
                                    <div style={{ textAlign: 'center' }}>
                                        <Slider discrete settings={{
                                            min: 5,
                                            max: 50,
                                            step: 5,
                                            onChange: value => this.setPageSize(value)
                                        }}
                                            value={this.props.prospectList.pageSize}
                                            color='yellow'
                                        />
                                    </div>
                                </Menu.Item>
                                {this.props.selectedForExport.size > 0 ?
                                    <Menu.Item>
                                        <Label>{this.props.selectedForExport.size}</Label>
                                        <Menu.Header as='h4'>Selection (WIP)</Menu.Header>
                                        <Menu.Menu>
                                            <Menu.Item>
                                                <Grid stackable>
                                                    <Grid.Row columns={3} centered textAlign='left'>
                                                        <Grid.Column textAlign='left' width={4}>
                                                            <Button basic size='tiny' icon='flag' disabled>
                                                                {/*<Icon name='flag'/>*/}
                                                            </Button>
                                                        </Grid.Column>
                                                        <Grid.Column textAlign='center' width={8}>
                                                            <Segment textAlign='center' basic compact
                                                                style={{
                                                                    padding: '0.78em',
                                                                    borderRadius: '.28571429rem'
                                                                }}>
                                                                <Rating defaultRating={0} disabled maxRating={5} />
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column textAlign='right' width={4}>
                                                            <Button size='tiny' basic
                                                                onClick={() => this.props.handleClearSelectedForExport()} icon='remove' />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Menu.Item>
                                        </Menu.Menu>
                                    </Menu.Item>
                                    : null
                                }
                                <Menu.Item
                                // name='Export'
                                >
                                    <Icon name='download' />
                                    <Menu.Header as='h4'>Export</Menu.Header>
                                    <Menu.Menu>
                                        {/* <div style={{margin: '0  0 0 1.2em'}}>Selected</div> */}
                                        {/* <Menu.Item
                                    disabled
                                    link>
                                    {this.props.selectedForExport.size > 0 && false ?
                                        <Label color='black' horizontal>
                                            {this.props.selectedForExport.size}
                                        </Label>
                                        : null}
                                    Salesforce
                                                    </Menu.Item> */}

                                        <Menu.Item
                                            link>
                                            {/* <Label color='black' horizontal>
                                                                {this.state.csvExportNum}
                                                            </Label> */}
                                            {/* <Slider discrete settings={{
                                                        start: 100,
                                                        min: 1,
                                                        max: this.state.totalRecords && (this.state.totalRecords > 1000) ? 1000 : this.state.totalRecords,
                                                        step: 5,
                                                        onChange: value => this.setCsvExportSize(value)
                                                    }}
                                                            value={this.state.csvExportNum}
                                                            color='yellow'
                                                    /> */}
                                        </Menu.Item>
                                        {/* This section creates a button to export selected items to a CSV */}
                                        {
                                            this.props.selectedForExport.size > 0 ?
                                                <ExportModal buttonColor='' functionality={this.handleSelectedProspectExport}>
                                                    <Menu.Item>
                                                        <Label color='blue' horizontal>
                                                            {this.props.selectedForExport.size} records
                                                                </Label>
                                                        Export Selected
                                        </Menu.Item>
                                                </ExportModal>
                                                : null
                                        }
                                        {
                                            this.props.totalRecords && this.props.totalRecords > this.props.prospectList.pageSize ?
                                                <ExportModal buttonColor='' functionality={this.handleBulkExport} >
                                                    <Menu.Item>
                                                        <Label color='green' horizontal>
                                                            {/* {this.state.csvExportNum ? this.state.csvExportNum : 0} records */} {this.props.totalRecords && this.props.totalRecords > 1000 ? 1000 : this.props.totalRecords} records
                                                                </Label>
                                                        Export Bulk
                                        </Menu.Item>
                                                </ExportModal>
                                                : null
                                        }

                                        <ExportModal buttonColor='' functionality={this.handleThisPageExport}>
                                            <Menu.Item>
                                                <Label color='yellow' horizontal>
                                                    {this.props.prospectList ? this.props.prospectList.pageSize : null} records
                                                        </Label>
                                                Export This Page
                                                    </Menu.Item>
                                        </ExportModal>
                                    </Menu.Menu>
                                </Menu.Item>
                            </div>
                        }
                    </Menu>
                </Segment>
            </Grid.Column >
        )
    }

}

function mapStateToProps({ prospectList, prospectInteraction }) {
    return { prospectList, prospectInteraction };
}

const mapDispatchToProps = {
    addFilter, removeFilter, setColumns, setPageSize, setSort, setPageNumber, toggleMapView
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
