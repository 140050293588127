import React, {Fragment} from 'react'
import MapComponent from "./components/MapComponent";

const  ProspectProfileMap = (props) => {
        return (
            <div style={{height: '200px'}}>
                    <MapComponent
                    lng={props.geo.lon}
                    lat={props.geo.lat}
                    />
            </div>
        )
}

export default ProspectProfileMap