import React, { Component } from 'react';
import {
    Button,
    Header,
    Image,
    Modal,
    Grid,
    Divider,
    Segment,
} from 'semantic-ui-react';
import ErrorBlock from './ErrorBlock';
import ZapierLogo from '../images/zapier-logo.png';
import CSVLogo from '../images/csv-logo.png';

class ExportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            error: false,
            statusCode: '',
        }
    }

    // Completes the passed down function of functionality 
    handleCsvExport = async () => {
        const statusCode = await this.props.functionality('csv');
        if (statusCode != 200) {
            this.setState({ error: true, statusCode: `There was an error. Error Code: ${statusCode}` })
        }
    }

    handleZapierExport = async () => {
        const statusCode = await this.props.functionality('zapier');
        if (statusCode != 200) {
            this.setState({ error: true, statusCode: `There was an error. Error Code: ${statusCode}` })
        }
    }

    handleOpenModal = () => {
        this.setState({ open: true, error: false })
    }

    handleCloseModal = () => {
        this.setState({ open: false })
    }

    render() {
        const { open, error, statusCode } = this.state
        const { width, height } = this.props;
        return (
            <>
                <div style={{ cursor: 'pointer', width: width, height: height }} onClick={this.handleOpenModal} >{this.props.children}</div>
                <Modal open={open} onClose={this.handleCloseModal} size='mini'>
                    <Modal.Header >How would you like to export?</Modal.Header>
                    <Modal.Content image>
                        {!error ?
                            <Segment size="massive">

                                <Grid columns={2} stackable textAlign='center'>
                                    <Divider vertical>Or</Divider>
                                    <Grid.Row verticalAlign='middle'>
                                        <Grid.Column  >

                                            <Image
                                                onClick={this.handleZapierExport}
                                                style={{ cursor: 'pointer' }}
                                                wrapped
                                                size='small'
                                                src={ZapierLogo}
                                                alt="Zapiere logo"
                                            />

                                        </Grid.Column>
                                        <Grid.Column >
                                            <Image

                                                onClick={
                                                    this.handleCsvExport}
                                                style={{ cursor: 'pointer' }}
                                                wrapped
                                                size='small'
                                                src={CSVLogo}
                                                alt='CSV Logo'
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                            </Segment>
                            : <ErrorBlock error={statusCode}></ErrorBlock>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={this.handleCloseModal}>
                            Cancel
                        </Button>
                        <Button positive onClick={this.handleCloseModal}>
                            Done
                        </Button>
                    </Modal.Actions>
                </Modal>

            </>
        )
    }
}

export default ExportModal;