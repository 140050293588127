import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// NOTE: THis is the verbose import. Come back to potentially minify by only importing the specific styles that we need. For example '~react-vis/dist/style/legends' would import only legends.
// import "react-vis/dist/style";
import './semantic/dist/semantic.min.css';

// REDUX
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

import Amplify, { Hub } from 'aws-amplify';
import { fetchUser } from './actions';

// All environment variables must start with REACT_APP_ for react to pick them up.
Amplify.configure({
    'Auth': {
        // Assumes default region is us-west
        'region': process.env['REACT_APP_AUTH_REGION'] || 'us-west-2',
        'userPoolId': process.env['REACT_APP_USER_POOL_ID'],
        'userPoolWebClientId': process.env['REACT_APP_USER_POOL_WEB_CLIENT_ID']
    }
});


const store = createStore(rootReducer, applyMiddleware(thunk));

// Call the redux action to update user in store on any Authentication event fired by cognito
Hub.listen('auth', data => {
    console.log('Auth Hook: ', data);
    store.dispatch(fetchUser(true));
});


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

