import React, { Component } from 'react'
import {
    ArcSeries,
    FlexibleXYPlot,
    Crosshair,
    VerticalGridLines,
    HorizontalGridLines,
    YAxis,
    XAxis,
} from 'react-vis'
import PropTypes from 'prop-types';
import { Button, Icon, Grid } from 'semantic-ui-react';
import { makeNumberPretty, toTitleCase } from "../../../util/lib";

// NOTE: This component can take a variety of different props to make the visual style different. You can enable X and Y axis lines, colors (in hex or pre-built english text), the width, the height, the labels for the axis, and even what kind of representation the data will have (bars, lines, marks). 

// NOTE: All data must be accepted as an object type with X and Y definitions for the data.

class Gauge extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            crosshairValues: [],
            yAxis: false,
            xAxis: false,
            VGridLine: false,
            HGridLine: false,
        }
    }

    // TODO: Implement these two functions to display chart information on mousehover.
    _onNearestX = (value, { index }) => {
        this.setState({ crosshairValues: [{ x: this.props.data[index].x, y: this.props.data[index].y }] });
    };

    _onMouseLeave = () => {
        this.setState({
            crosshairValues: [],
            yAxis: false,
            xAxis: false,
            VGridLine: false,
            HGridLine: false
        });
    };

    _onMouseEnter = () => {
        this.setState({
            yAxis: true,
            xAxis: true,
            VGridLine: true,
            HGridLine: true
        })
    }


    render() {
        const { data, color } = this.props;
        const { yAxis, xAxis, VGridLine, HGridLine } = this.state;
        return (
            <>
                <FlexibleXYPlot
                    style={{ marginTop: 15, height: '100%' }}
                    xDomain={[-50, 50]}
                    yDomain={[-50, 50]}
                    color={color}
                    onMouseLeave={this._onMouseLeave}
                    onMouseEnter={this._onMouseEnter}
                >
                    <Crosshair
                        values={this.state.crosshairValues}
                        className='test'

                    />
                    {VGridLine ?
                        <VerticalGridLines />
                        : null
                    }
                    {HGridLine ?
                        <HorizontalGridLines />
                        : null
                    }
                    {xAxis ?
                        <XAxis />
                        : null}

                    {yAxis ?
                        <YAxis /> : null}
                    <ArcSeries
                        onNearestX={this._onNearestX}
                        animation
                        // NOTE: Radius domain is the maximum that the radius can be.
                        // radiusDomain={[-1, 1]}
                        // NOTE: radiusType is undefined in the official documentation...
                        // radiusType={'literal'}
                        center={{ x: 0, y: 0 }}
                        data={data} ></ArcSeries>
                </FlexibleXYPlot>
            </>
        )
    }
}

Gauge.propTypes = {
    type: PropTypes.string,
    height: PropTypes.number,
    yAxis: PropTypes.bool,
    xAxis: PropTypes.bool,
    color: PropTypes.string,

}

export default Gauge;