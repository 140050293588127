import React, {Fragment} from 'react'; // eslint-disable-line no-unused-vars

import {SignIn} from 'aws-amplify-react';


// import whLogo from '../images/wh-logo.webp';

import AuthWrapper from './components/AuthWrapper';

// ------------------------------ No Imports Below Here ------------------------------------------
// TODO figure out how to convert these to imports to avoid this issue.

var _core = require('@aws-amplify/core');

var _react2 = _interopRequireDefault(React);

var _AmplifyUI = require('aws-amplify-react/dist/AmplifyUI');

var _FederatedSignIn = require('aws-amplify-react/dist/Auth/FederatedSignIn');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class CustomSignIn extends SignIn
{

    constructor(props)
    {

        super(props);

        if (!this.state)
        {

            this.state = {};
        }

        // this.superComponentDidMount = this.componentDidMount;
        //
        // this.componentDidMount = () =>
        // {
        //
        //     if (typeof this.superComponentDidMount === 'function')
        //     {
        //
        //         this.superComponentDidMount();
        //     }
        //
        //     if (window.document)
        //     {
        //
        //         window.document.title = 'Sign In | ' + (process.env['REACT_APP_SITE_TITLE'] || 'Wheelhouse Boilerplate App');
        //     }
        // };
    }

    showComponent(theme)
    {

        if (window.document)
        {

            window.document.title = 'Sign In | ' + (process.env['REACT_APP_SITE_TITLE'] || 'Wheelhouse Boilerplate App');
        }

        var _this4 = this;

        var _props = this.props,

            authState = _props.authState,

            hide = _props.hide,

            federated = _props.federated,

            onStateChange = _props.onStateChange;

        if (hide && (hide.includes(SignIn) || hide.includes(CustomSignIn)))
        {

            return null;
        }

        return (

            <AuthWrapper>

                {
                    _react2.default.createElement(

                        _AmplifyUI.FormSection,

                        {theme: theme},

                        _react2.default.createElement(

                            _AmplifyUI.SectionHeader,

                            {theme: theme},

                            _core.I18n.get('Log-in to Your Account')
                        ),
                        // // This works.
                        // (
                        //     <div>test</div>
                        // )
                        _react2.default.createElement(

                            _AmplifyUI.SectionBody,

                            {theme: theme},

                            _react2.default.createElement(_AmplifyUI.InputRow,
                            {
                                autoFocus: true,

                                placeholder: _core.I18n.get('E-mail Address'),

                                theme: theme,

                                key: 'username',

                                name: 'username',

                                onChange: this.handleInputChange
                            }),

                            _react2.default.createElement(_AmplifyUI.InputRow,
                            {

                                placeholder: _core.I18n.get('Password'),

                                theme: theme,

                                key: 'password',

                                type: 'password',

                                name: 'password',

                                onChange: this.handleInputChange
                            }),


                            _react2.default.createElement(

                                _AmplifyUI.ButtonRow,

                                {theme: theme, onClick: this.signIn},

                                _core.I18n.get('Sign In')
                            ),

                            _react2.default.createElement(_FederatedSignIn.FederatedButtons,
                            {

                                federated: federated,

                                theme: theme,

                                authState: authState,

                                onStateChange: onStateChange
                            }),
                            _react2.default.createElement(

                                _AmplifyUI.Link,
                                {

                                    theme: theme, onClick: function onClick()
                                    {

                                        return _this4.changeState('forgotPassword');
                                    }
                                },

                                _core.I18n.get('Forgot Password')
                            ),
                        ),
                        // _react2.default.createElement(
                        //
                        //     _AmplifyUI.SectionFooter,
                        //
                        //     {theme: theme},
                        //
                        //     _react2.default.createElement(
                        //
                        //         'div',
                        //
                        //         {style: theme.col6},
                        //
                        //         _react2.default.createElement(
                        //
                        //             _AmplifyUI.Link,
                        //             {
                        //
                        //                 theme: theme, onClick: function onClick()
                        //                 {
                        //
                        //                     return _this4.changeState('forgotPassword');
                        //                 }
                        //             },
                        //
                        //             _core.I18n.get('Forgot Password')
                        //         )
                        //     ),
                            // // no sign up for you.
                            // _react2.default.createElement(
                            //
                            //     'div',
                            //
                            //     {style: Object.assign({textAlign: 'right'}, theme.col6)},
                            //
                            //     _react2.default.createElement(
                            //
                            //         _AmplifyUI.Link,
                            //         {
                            //
                            //             theme: theme, onClick: function onClick()
                            //             {
                            //
                            //                 return _this4.changeState('signUp');
                            //             }
                            //         },
                            //
                            //         _core.I18n.get('Sign Up')
                            //     )
                            // )
                        // )
                    )
                }

            </AuthWrapper>

        );
    }
}

export default CustomSignIn;