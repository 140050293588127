import {
    ADD_FILTER,
    EDIT_FILTER,
    FETCH_USER,
    REMOVE_FILTER,
    SET_COLUMNS,
    SET_PAGE_NUMBER,
    SET_PAGE_SIZE, SET_PROSPECT_INTERACTION_STATE, SET_PROSPECT_LIST_STATE,
    SET_SORT, SET_STARS, TOGGLE_FLAG, TOGGLE_MAP_VIEW, SET_ZOOM, SET_COORDINATES
} from './types';
import {Auth} from 'aws-amplify';
import {getAttributeFunctor} from "react-vis/es/utils/scales-utils";
//AUTH
export const fetchUser = (refresh = false) => async dispatch => {
    const whUserGroup = 'mep-wheelhouse'
    const notClientGroups = ['admin', 'owner', 'sudoer', 'user'];
    Auth.currentAuthenticatedUser({
        bypassCache: refresh  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
        .then(async u => {
            const user = { ...u.attributes};
            // user['email'] = u.attributes.email;
            const callingUserGroups = u.signInUserSession.idToken['payload']['cognito:groups'] ? u.signInUserSession.idToken['payload']['cognito:groups'] : [];
            const callingUserClientGroups = callingUserGroups.filter(g => !notClientGroups.includes(g))
            let callingUserClientGroup = null;
            if (callingUserClientGroups.length === 2) {
                if (callingUserClientGroups.includes(whUserGroup)) {
                    callingUserClientGroup = callingUserClientGroups.filter(g => g !== whUserGroup)[0]
                } else {
                    dispatch({type: FETCH_USER, payload: false})
                    return;
                }
            } else if (callingUserClientGroups.length === 1) {
                callingUserClientGroup = callingUserClientGroups[0]
            } else if (callingUserClientGroups.length > 2) {
                dispatch({type: FETCH_USER, payload: false})
                return;
            } else {
                dispatch({type: FETCH_USER, payload: false})
                return;
            }
            user['group'] = callingUserClientGroup
            // console.log('USER FETCHED ', user);
            dispatch({type: FETCH_USER, payload: user});
        })
        .catch(err => dispatch({type: FETCH_USER, payload: false}));

};

//PROSPECT LIST
export const setProspectListState = prospectList => ({
    type: SET_PROSPECT_LIST_STATE,
    payload: {prospectList}
})
//FILTER
export const addFilter = filter => ({
    type: ADD_FILTER,
    payload: filter
});

export const removeFilter = index => ({
    type: REMOVE_FILTER,
    payload: {index}
});
export const editFilter = (index, filter) => {
    return {
        type: EDIT_FILTER,
        payload: {index, filter}
    }
}

// COLUMNS
export const setColumns = columns => ({
    type: SET_COLUMNS,
    payload: {columns}
});

// PAGE
export const setPageSize = pageSize => ({
    type: SET_PAGE_SIZE,
    payload: {pageSize}
});

export const setPageNumber = pageNumber => ({
    type: SET_PAGE_NUMBER,
    payload: {pageNumber}
})

// SORT
export const setSort = (sortColumn, sortDirection) => ({
    type: SET_SORT,
    payload: {sortColumn, sortDirection}
})

// MAP OPTIONS

export const toggleMapView = mapView => ({
    type: TOGGLE_MAP_VIEW,
    payload: {mapView}
})

export const setZoom = zoom => ({
    type: SET_ZOOM,
    payload: {zoom}
})

export const setCoordinates = (lat, lng) => ({
    type: SET_COORDINATES,
    payload: {lat, lng}
})

// PROSPECT INTERACTION

export const setStars = (org_id, stars) => ({
    type: SET_STARS,
    payload: {org_id, stars}
})

export const toggleFlag = (org_id) => ({
    type: TOGGLE_FLAG,
    payload: {org_id}
})

export const setProspectInteractionState = (userState) => {
    console.log("ACTIon - ", userState)
    return {
        type: SET_PROSPECT_INTERACTION_STATE,
        payload:
            {
                userState
            }
    }
}