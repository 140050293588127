import React, { Component } from 'react'

import PropTypes from 'prop-types';
import { Loader, Dimmer, Table } from 'semantic-ui-react';
import callApi from '../../../util/callApi';

class GridList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // Fake data
            data: [
                {
                    name: 'Test!',
                    current_rank: 100,
                    previous_rank: 50,
                    delta: 50

                },
                {
                    name: 'This is the name of My Corp!',
                    current_rank: 23,
                    previous_rank: -5,
                    delta: -5

                }, {
                    name: 'Test!',
                    current_rank: 23,
                    previous_rank: -5,
                    delta: -5

                }, {
                    name: 'Test!',
                    current_rank: 23,
                    previous_rank: -5,
                    delta: -5

                }, {
                    name: 'Test!',
                    current_rank: 23,
                    previous_rank: -5,
                    delta: -5

                },
                {
                    name: 'Test!',
                    current_rank: 100,
                    previous_rank: 50,
                    delta: 50

                },
                {
                    name: 'This is the name of My Corp!',
                    current_rank: 23,
                    previous_rank: -5,
                    delta: -5

                }, {
                    name: 'Test!',
                    current_rank: 23,
                    previous_rank: -5,
                    delta: -5

                }, {
                    name: 'Test!',
                    current_rank: 23,
                    previous_rank: -5,
                    delta: -5

                }, {
                    name: 'Test!',
                    current_rank: 23,
                    previous_rank: -5,
                    delta: -5

                },

            ],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        // TODO: COde here for calling data endpoint await
        this.setState({ isLoading: false })
    }

    // If the delta is positive, green. Negative, red.
    handleTrendValueColor = (delta) => {
        if (Math.sign(delta) >= 0) {
            return {
                positive: true,
                negative: false
            }
        } else if (Math.sign(delta) <= -0) {
            return {
                positive: false,
                negative: true
            }
        } else {
            return {
                positive: false,
                negative: false
            }

        }
    }

    handleBuildProspectList = (data) => {
        return data.map((prospect, i) => (
            < Table.Row key={i} >
                <Table.Cell>{prospect.name}</Table.Cell>
                <Table.Cell>{prospect.current_rank}</Table.Cell>
                <Table.Cell>{prospect.previous_rank}</Table.Cell>
                <Table.Cell positive={this.handleTrendValueColor(prospect.delta).positive} negative={this.handleTrendValueColor(prospect.delta).negative}>{prospect.delta}</Table.Cell>
            </Table.Row >
        ))
    }

    render() {
        const { color } = this.props;
        const { data, isLoading } = this.state;
        return (
            <>
                {!isLoading ?
                    <Table color={color} celled inverted padded>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell singleLine>Prospect</Table.HeaderCell>
                                <Table.HeaderCell>Current Rank</Table.HeaderCell>
                                <Table.HeaderCell>Previous Rank</Table.HeaderCell>
                                <Table.HeaderCell>Delta</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.handleBuildProspectList(data)}
                        </Table.Body>
                    </Table>

                    :
                    <Dimmer active>
                        <Loader>Loading</Loader>
                    </Dimmer>
                }
            </>
        )
    }
}

GridList.propTypes = {
    type: PropTypes.string,
    apiUrl: PropTypes.string
}

export default GridList;