import React, {Fragment} from 'react';
import {Container, Grid} from "semantic-ui-react";
import SideMenu from './components/sideMenu'
import KeyTermManager from "./components/KeyTermManager";
import {Helmet} from "react-helmet/es/Helmet";

class Tools extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    renderTool = () => {
        const toolName = 'key-term-manager'
        switch(toolName) {
            case "key-term-manager": {
                return <KeyTermManager/>
            }
            default: return ''
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Tools | Mozaic</title>
                </Helmet>
            <Grid container>
                <Grid.Column width={3}>
                    <SideMenu/>
                </Grid.Column>
                <Grid.Column width={13}>
                    {this.renderTool()}
                </Grid.Column>
            </Grid>
            </>
        )
    }
}

export default Tools