
import React from 'react'; // eslint-disable-line no-unused-vars

import {RequireNewPassword} from 'aws-amplify-react';

import AuthWrapper from "./components/AuthWrapper";

var _react2 = _interopRequireDefault(React);

var _AmplifyUI = require('aws-amplify-react/dist/AmplifyUI');

var _core = require('@aws-amplify/core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class CustomRequireNewPassword extends RequireNewPassword
{

    constructor(props)
    {

        super(props);

        this.superShowComponent = this.showComponent;

        this.showComponent = (theme) =>
        {

            if (window.document)
            {

                window.document.title = 'Set New Password | ' + (process.env['REACT_APP_SITE_TITLE'] || 'Wheelhouse Boilerplate App');
            }

            var _this4 = this;

            var hide = this.props.hide;

            if (hide && hide.includes(RequireNewPassword)) {
                return null;
            }

            return (
                <AuthWrapper>

                    {
                        _react2.default.createElement(
                            _AmplifyUI.FormSection,
                            { theme: theme },
                            _react2.default.createElement(
                                _AmplifyUI.SectionHeader,
                                { theme: theme },
                                _core.I18n.get('Change Password')
                            ),
                            _react2.default.createElement(
                                _AmplifyUI.SectionBody,
                                null,
                                _react2.default.createElement(_AmplifyUI.InputRow, {
                                    autoFocus: true,
                                    placeholder: _core.I18n.get('New Password'),
                                    theme: theme,
                                    key: 'password',
                                    name: 'password',
                                    type: 'password',
                                    onChange: this.handleInputChange
                                }),
                                _react2.default.createElement(
                                    _AmplifyUI.Link,
                                    { theme: theme, onClick: function onClick() {
                                            return _this4.changeState('signIn');
                                        } },
                                    _core.I18n.get('Back to Sign In')
                                ),
                                _react2.default.createElement(
                                    _AmplifyUI.ButtonRow,
                                    { theme: theme, onClick: this.change },
                                    _core.I18n.get('Change')
                                )
                            ),
                            // _react2.default.createElement(
                            //     _AmplifyUI.SectionFooter,
                            //     { theme: theme },
                            //     _react2.default.createElement(
                            //         _AmplifyUI.Link,
                            //         { theme: theme, onClick: function onClick() {
                            //                 return _this4.changeState('signIn');
                            //             } },
                            //         _core.I18n.get('Back to Sign In')
                            //     )
                            // )
                        )
                    }

                </AuthWrapper>
            );
        };

        // this.superComponentDidMount = this.componentDidMount;
        //
        // this.componentDidMount = () =>
        // {
        //
        //     if (typeof this.superComponentDidMount === 'function')
        //     {
        //
        //         this.superComponentDidMount();
        //     }
        //
        //     if (window.document)
        //     {
        //
        //         window.document.title = 'Set New Password | ' + (process.env['REACT_APP_SITE_TITLE'] || 'Wheelhouse Boilerplate App');
        //     }
        // };
    }
}

export default CustomRequireNewPassword;