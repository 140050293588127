import React from 'react'
import {Header, Menu} from "semantic-ui-react";

function SideMenu(props) {
    return (
        <Menu vertical fluid>
            <Menu.Item>
                <Header as='h4'>Features</Header>
            </Menu.Item>
            <Menu.Item
                name='key-term-manager'
                active={true}
                onClick={()=>{return}}
            />
            <Menu.Item
                disabled
                name='mosaic-lead-score'
                // active={}
                // onClick={this.handleItemClick}
            />
            <Menu.Item
                disabled
                name='contact-mosaic'
                // active={}
                // onClick={this.handleItemClick}
            />
            <Menu.Item
                disabled
                name='natural-language-processing'
                // active={}
                // onClick={this.handleItemClick}
            />
            <Menu.Item
                disabled
                name='Export'
                // active={}
                // onClick={this.handleItemClick}
            />
            <Menu.Item>
                <Header as='h4'>Integrations</Header>
            </Menu.Item>
            <Menu.Item
                name='salesforce'
                disabled
                onClick={()=>{return}}
            />
            <Menu.Item
                name='zapier'
                disabled
                onClick={()=>{return}}
            />
            <Menu.Item
                name='hubspot'
                disabled
                onClick={()=>{return}}
            />
            <Menu.Item>
                <Header as='h4'>Administration</Header>
            </Menu.Item>
            <Menu.Item
                name='user-management'
                disabled
                onClick={()=>{return}}
            />
        </Menu>
    )

}

export default SideMenu